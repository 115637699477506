<template class="dashboard-map">
    <div class="d-flex map-container" v-if="showMap">
        <transition name="slide-fade" appear>
            <div class="dashboard-overlay-card" :class="showDashboardControl ? '' : 'd-md-block d-none'">
                <b-row class="text-center w-100 h-100" align-v="center"
                       v-if="areDataLoading">
                    <b-col>
                        <span class="loader"></span>
                    </b-col>
                </b-row>
                <DashboardOverlayCard @triggerControl="triggerControl" v-else/>
            </div>
        </transition>
        <div class="map-size"
             :class="showDashboardControl ? 'open-dashboard' : ''"
             id="customMap"
             ref="customMap"
             @load="calculateHeight">
            <l-map
                @ready="setMapLoaded"
                @update:zoom="zoomUpdate"
                :zoom="zoom"
                :center="center"
                :maxZoom="getMaxZoom"
                :minZoom="getMinZoom"
                :options="mapOptions"
                @update:center="centerUpdate"
                ref="map"
                id="map"
                @click="resetChosenSensors()"
            >
                <l-tile-layer
                    :url="url"
                />


                <!-- >>>>>>>>>>  BEGINNING OF MAP CONTROLS  <<<<<<<<<<< -->

                <l-control-zoom position="bottomleft"></l-control-zoom>

                <!-- >>>>>>>>>>  CLIENT LOGO  <<<<<<<<<<< -->
                <!--                <l-control position="bottomleft" v-if="!showDashboardControl">-->
                <!--                    <div class="text-center logo-account-container" v-if="currentAccount.logoName">-->
                <!--                        <b-img :src="`${assetsBucket}/assets/account/${currentAccount.logoName}`"-->
                <!--                               class="logo-account"/>-->
                <!--                    </div>-->
                <!--                </l-control>-->

                <!-- >>>>>>>>>>  SENSORS FILTERS  <<<<<<<<<<< -->
                <l-control position="topcenter" class="w-100">
                    <DashboardFilters
                        id="no-scrolling-filters"
                        class="w-100"
                        :class="showFilters ? '' : 'd-md-flex d-none'"
                        @triggerFilters="triggerFilters"
                        :is-empty="sensors.length === 0 && isFiltersOpen"/>
                </l-control>
                <l-control position="topright"
                           class="d-block d-md-none m-0 dashboard-control w-25"
                           id="no-scrolling-control" v-if="!showFilters">
                    <b-avatar icon="filter"
                              class="toggle-dashboard"
                              style="float:right;"
                              @click.native="triggerFilters"
                    />
                </l-control>

                <!-- >>>>>>>>>>  TERRITORY DASHBOARD  <<<<<<<<<<< -->
                <l-control position="topleft"
                           class="d-block d-md-none m-0 dashboard-control w-25"
                           id="no-scrolling-control">
                    <b-avatar icon="bar-chart-line-fill" class="toggle-dashboard" @click.native="triggerControl"
                              v-if="!showDashboardControl"/>
                </l-control>

                <!-- >>>>>>>>>>  DASHBOARD LEGEND  <<<<<<<<<<< -->
                <l-control position="bottomright" class="d-none d-md-block dashboard-control-legend row">
                    <transition name="slide-fade" appear mode="out-in">
                        <div class="dashboard-legend flex-column justify-content-around" v-if="showLegend">
                            <div v-for="(level, index) in riskList" :key="index"
                                 class="text-center align-self-center dashboard-legend-item">
                                <b-icon icon="circle-fill" class="dashboard-legend-icon"
                                        :class="`clr-${level.color}`"/>
                                <p class="subtitle m-0" :class="`clr-${level.color}`"
                                   v-if="level.value > 1">{{ $t('risk.this') | capitalize }}</p>
                                <p class="subtitle m-0" :class="`clr-${level.color}`">{{ level.name }}</p>
                                <p class="subtitle m-0" :class="`clr-${level.color}`"
                                   v-if="level.value === 1">{{ $t('risk.this') | capitalize }}</p>
                            </div>
                        </div>
                    </transition>
                    <div class="dashboard-legend-trigger text-center" @click="triggerLegend">
                        <b-icon icon="circle-fill" class="dashboard-legend-icon"/>
                        <p class="subtitle clr-white m-0">{{ $t('common.button.legend') | capitalize }}</p>
                    </div>
                </l-control>
                <!-- >>>>>>>>>>  END OF MAP CONTROLS  <<<<<<<<<<< -->


                <!-- >>>>>>>>>>  START OF SENSORS MARKERS  <<<<<<<<<<< -->
                <l-feature-group>
                    <l-marker-cluster :options="clusterOptions">
                        <l-marker
                            :lat-lng="sensor.position"
                            @click="choseSensor(sensor.data)"
                            v-for="(sensor,index) in sensorMarkers"
                            :icon="getIcon(sensor)"
                            @update:icon="getIcon(sensor)"
                            :key="index"
                            :ref="'marker'+sensor.serialNumber"
                        >
                        </l-marker>
                    </l-marker-cluster>
                </l-feature-group>
                <!-- >>>>>>>>>>  END OF SENSORS MARKERS  <<<<<<<<<<< -->

                <!-- >>>>>>>>>>  START OF USER REPORTS MARKERS  <<<<<<<<<<< -->
                <l-feature-group>
                    <l-marker
                        :lat-lng="userReport.position"
                        v-for="(userReport,index) in userReportsMarkers"
                        :icon="getUserReportIcon()"
                        @update:icon="getUserReportIcon()"
                        :key="index"
                        :ref="'marker_user_report'+userReport.id"/>
                </l-feature-group>
                <!-- >>>>>>>>>>  END OF USER REPORTS MARKERS  <<<<<<<<<<< -->
            </l-map>
        </div>
    </div>
    <div v-else class="loader-live-pollen">
        <IconBase view-box="0 0 150 150" class-name="loader-live-pollen" id-name="loader-live-pollen"
                  height="150" width="150" icon-name="live-pollen-icon">
            <IconLivePollen/>
        </IconBase>
    </div>
</template>

<script>
import SecureLS from "@/plugins/secureLS";
import {latLng, latLngBounds} from 'leaflet';
import {LControl, LControlZoom, LFeatureGroup, LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import Vuex from 'vuex';
import DashboardOverlayCard from "./DashboardOverlayCard";
import DashboardFilters from "@/components/dashboard/MapElements/DashboardFilters";
import pollenService from '@/services/pollen/pollenService';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import IconBase from "../common/icons/IconBase";
import IconLivePollen from "../common/icons/IconLivePollen"
import moment from "moment";
import risk from "@/services/risk/risk";
import geolocationService from "@/services/calcul/geolocationService";

const L = window.L;

export default {
    name: "dashboard-map",
    components: {
        DashboardOverlayCard,
        DashboardFilters,
        LMap,
        LTileLayer,
        LMarker,
        LControlZoom,
        LFeatureGroup,
        LControl,
        "l-marker-cluster": Vue2LeafletMarkerCluster,
        IconBase,
        IconLivePollen
    },
    data() {
        return {
            height: 0,
            zoom: 10,
            center: null,
            url: `https://api.mapbox.com/styles/v1/lifyair/cl1c47qc500aq14o3vspod2w9/tiles/512/{z}/{x}/{y}@2x?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`,
            currentZoom: 10,
            currentCenter: latLng(47.90289, 1.90389),
            mapOptions: {
                zoomSnap: 0.5,
                scrollWheelZoom: true,
                tileSize: 512,
                zoomOffset: 10,
                maxBoundsViscosity: 1,
                zoomControl: false
            },
            showMap: false,
            sensorMarkers: [],
            userReportsMarkers: [],
            mapReady: false,
            initBounds: [],
            maxBounds: [],
            showDashboardControl: false,
            loading: false,
            showLegend: false,
            showFilters: false,
            isFiltersOpen: false,
            latitudes: [],
            longitudes: [],
            maxZoom: 0,
            minZoom: 0,
            boundingBox: null
        };
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors',
        }),
        ...Vuex.mapActions('filters', {
            setFilters: 'setFilters',
            setDataLoading: 'setDataLoading',
        }),
        ...Vuex.mapActions('userReport', {
            setUserReports: 'setUserReports',
            setUserReportsHistory: 'setUserReportsHistory',
        }),
        ...Vuex.mapActions('notificationSettings', {
            setNotificationSettings: 'setNotificationSettings',
        }),
        ...Vuex.mapActions('sensorAnalytic', {
            setSensorsConnexionCount: 'setSensorsConnexionCount',
        }),
        ...Vuex.mapActions('rollingRisk', {
            setGlobalActualRollingRisks: 'setGlobalActualRollingRisks',
            setGlobalRollingRisksHistory: "setGlobalRollingRisksHistory"
        }),
        ...Vuex.mapActions('globalConcentration', {
            setGlobalConcentrations: "setGlobalConcentrations"
        }),
        ...Vuex.mapActions('account', {
            setCurrentAccount: 'setCurrentAccount',
        }),
        ...Vuex.mapActions('login', {
            logout: 'logout'
        }),
        calculateHeight() {
            if (this.$refs.customMap) {
                this.height = this.$refs.customMap.clientHeight;
            }
        },
        getMeanValueOfArray(arr) {
            const mid = Math.floor(arr.length / 2),
                nums = [...arr].sort((a, b) => a - b);
            return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
        },
        choseSensor(sensor) {
            this.setFilters({sensors: [sensor], fromMap: true})
        },
        resetChosenSensors() {
            if (this.filters.fromMap) {
                this.setFilters({sensors: [], fromMap: false})
            }
        },
        async setSensorsPosition() {
            await this.resetMapOptions();
            let sensors = this.sensors.filter(item => item.status !== 'deploy')
            for (let i = 0; i < sensors.length; i++) {
                let sensor = sensors[i]
                if (sensor.lat && sensor.lon) {
                    let maxRisk = this.getMaxRiskBySensor[sensor.serialNumber];
                    if (maxRisk === undefined) maxRisk = 'off';
                    this.sensorMarkers.push({
                        serialNumber: sensor.serialNumber,
                        position: latLng(sensor.lat, sensor.lon),
                        maxRisk: maxRisk,
                        lastUpdate: sensor.lastUpdate,
                        data: sensor
                    })

                    this.initBounds.push(latLng(sensor.lat, sensor.lon))
                    this.latitudes.push(sensor.lat)
                    this.longitudes.push(sensor.lon)
                    this.center =
                        latLng(this.getMeanValueOfArray(this.latitudes), this.getMeanValueOfArray(this.longitudes));
                }
            }

            if (this.sensorMarkers.length === 0) {
                this.initBounds = latLng(46.71109, 1.7191036);
                this.center = latLng(46.71109, 1.7191036);
            } else {
                this.makeBounds();
            }
            this.showMap = true
        },
        resetMapOptions() {
            this.sensorMarkers = []
            this.initBounds = [];
            this.latitudes = [];
            this.longitudes = [];
            this.center = null;
        },
        setUserReportsPosition() {
            this.userReportsMarkers = [];
            let userReports = this.userReports;
            for (let i = 0; i < userReports.length; i++) {
                let userReport = userReports[i]
                let approximateLat = 0;
                let approximateLong = 0;
                if (Math.random() < 0.5) {
                    approximateLat = userReport.latitude + (Math.random() * (0.002 - 0.001));
                } else {
                    approximateLat = userReport.latitude - (Math.random() * (0.002 - 0.001));
                }
                if (Math.random() < 0.5) {
                    approximateLong = userReport.longitude + (Math.random() * (0.002 - 0.001));
                } else {
                    approximateLong = userReport.longitude - (Math.random() * (0.002 - 0.001));
                }
                this.userReportsMarkers.push({
                    id: userReport.id,
                    exactPosition: false,
                    position: latLng(approximateLat, approximateLong),
                })
            }
        },
        getIcon(item) {
            let html = '';
            let color = ''
            let stroke = ''
            let stringColor = ''
            let iconSize = [32, 40];
            let isChosen = false;
            let className = 'custom-map-pop-up';
            const now = moment();
            let isSensorOn = moment(item.lastUpdate) >= now.subtract(24, 'hours');
            if (isSensorOn) {
                stringColor = pollenService.getColorByValue(item.maxRisk);
                color = pollenService.getHexColorByValue(item.maxRisk);
                stroke = pollenService.getHexColorByValue(item.maxRisk);
            } else {
                stringColor = pollenService.getColorByValue('off')
                color = pollenService.getHexColorByValue('off');
                stroke = pollenService.getHexColorByValue('off');
            }

            if (this.filters.fromMap) {
                isChosen = this.filters.sensors[0].serialNumber === item.serialNumber;
                if (isChosen) className += ' chosen'
            }


            if (isChosen) {
                html = `<svg width="56" height="70" viewBox="0 0 56 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28 67.3644C19.3418 59.8677 12.9011 52.9486 8.61272 46.6105C4.10913 39.9542 2 33.9988
                         2 28.7C2 20.4208 4.64137 14.0151 9.79968 9.25769C15.0732 4.39403 21.1125 2 28 2C34.8875 2
                         40.9268 4.39403 46.2003 9.25769C51.3586 14.0151 54 20.4208 54 28.7C54 33.9988 51.8909 39.9542
                         47.3873 46.6105C43.0989 52.9486 36.6582 59.8677 28 67.3644Z"
                         fill="white" stroke="${color}" stroke-width="4"/>
                        <circle cx="28" cy="28" r="11" fill="${color}"/>
                        </svg>`
                iconSize = [56, 70]
            } else {
                html = `<svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 39.3425C10.816 34.8982 6.95155 30.7821 4.38912 26.9948C1.77259 23.1276 0.5 19.5997 0.5
                     16.4C0.5 11.5177 2.06503 7.67565 5.16398 4.81755C8.29174 1.93288 11.8969 0.5 16 0.5C20.1031 0.5
                     23.7083 1.93288 26.836 4.81755C29.935 7.67565 31.5 11.5177 31.5 16.4C31.5 19.5997 30.2274 23.1276
                     27.6109 26.9948C25.0485 30.7821 21.184 34.8982 16 39.3425Z" fill="${color}" stroke="${stroke}"/>
                    </svg>`
            }


            return L.divIcon({
                className: className,
                html: html,
                iconSize: iconSize,
                serialNumber: item.serialNumber,
                isChosen: isChosen,
                stringColor: stringColor
            });
        },
        getUserReportIcon() {
            let html = '';
            let color = '#FF5A00'
            html = `
                <svg class="button" expanded="true" height="25" width="25">
                    <circle cx="50%" cy="50%" r="50%" style="fill:${color};" opacity="0.8"></circle>
                </svg>`

            return L.divIcon({
                className: "custom-map-pop-up user-reports",
                html: html,
                iconSize: [20, 20],
            });
        },
        setMapLoaded() {
            this.mapReady = true
            this.$refs.map.mapObject.fitBounds(this.initBounds, {
                paddingTopLeft: [0, 150],
                maxZoom: this.getMaxZoom,
                minZoom: this.getMinZoom
            });
            let dashboard = document.getElementById('no-scrolling-control');
            let filters = document.getElementById('no-scrolling-filters');
            if (dashboard) L.DomEvent.disableScrollPropagation(dashboard);
            if (filters) L.DomEvent.disableScrollPropagation(filters);
        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        triggerControl() {
            this.showDashboardControl = !this.showDashboardControl
        },
        triggerFilters() {
            this.showFilters = !this.showFilters
        },
        triggerLegend() {
            this.showLegend = !this.showLegend
        },
        async updateSensors(params = {}) {
            await this.setSensors(params).then(async () => {
                await this.setSensorsPosition();
            });
        },
        async updateRollingRisks(params = {}) {
            let historyParams = JSON.parse(JSON.stringify(params));
            await this.setGlobalActualRollingRisks(params);
            if (params.params && params.params.date) {
                historyParams.params.date = moment(historyParams.params.date).add(1, 'day').format()
            }
            if (!this.isAuthorized) {
                if (this.filters.riskLevels.length === 0) {
                    await this.setGlobalRollingRisksHistory(historyParams);
                }
            } else if (this.filters.accounts.length > 0 && this.filters.riskLevels.length === 0) {
                await this.setGlobalRollingRisksHistory(historyParams);
            }
        },
        updateGlobalConcentrations(params = {}) {
            if (this.isAuthorized && this.filters.accounts.length > 0) {
                this.setGlobalConcentrations(params);
            }
        },
        makeBounds() {
            const maxLat = Math.max(...this.latitudes) + 0.02;
            const minLat = Math.min(...this.latitudes) - 0.02;
            const maxLng = Math.max(...this.longitudes) + 0.02;
            const minLng = Math.min(...this.longitudes) - 0.02;
            this.initBounds = latLngBounds([
                [minLat, minLng],
                [maxLat, maxLng]
            ])

            if (this.sensorMarkers.length === 0) {
                this.setMinZoom(6);
                this.setMaxZoom(6);
            } else if (this.isAuthorized) {
                this.setMinZoom(0);
                this.setMaxZoom(20);
            } else {
                this.setMinZoom(6);
                this.setMaxZoom(17);
            }
            if (this.$refs.map && this.$refs.map.mapObject) {
                // TO DISPLAY THE DEBUGGING BOUNDING BOX
                // if(this.boundingBox) this.$refs.map.mapObject.removeLayer(this.boundingBox);
                // const boundingBox = L.rectangle(this.initBounds, {color: "#ff7800", weight: 1});
                // this.boundingBox = boundingBox;
                // this.$refs.map.mapObject.addLayer(boundingBox);
                this.$refs.map.mapObject.fitBounds(this.initBounds, {
                    paddingTopLeft: [0, 150],
                    maxZoom: this.getMaxZoom,
                    minZoom: this.getMinZoom
                });
            }
            this.zoomUpdate(this.getMinZoom);
        },
        calculateMaxDistance() {
            let distances = [];
            this.sensorMarkers.forEach((marker) => {
                distances.push(geolocationService.getDistance(
                    this.center.lat,
                    this.center.lng,
                    marker.position.lat,
                    marker.position.lng,
                ))
            })
            return Math.max(...distances)
        },
        areOtherFiltersNull(excludedField = []) {
            let res = true;
            Object.entries(this.filters).forEach(([field, value]) => {
                if (!excludedField.includes(field)) {
                    if (Array.isArray(value)) {
                        if (value.length > 0) {
                            res = false
                        }
                    } else if (typeof value === 'object' && value) {
                        res = false;
                    } else if (typeof value === 'boolean' && value) {
                        res = false;
                    } else if (value) {
                        res = false;
                    }
                }
            })
            return res;
        },
        setMaxZoom(value) {
            this.maxZoom = value;
        },
        setMinZoom(value) {
            this.minZoom = value;
        },
        calculateDistance(lat1, lon1, lat2, lon2) {
            /**
             * Calculates the distance between two latitude-longitude points using the Haversine formula.
             */
            const earthRadiusKm = 6371.0;
            const degreesToRadians = Math.PI / 180;

            const dLat = (lat2 - lat1) * degreesToRadians;
            const dLon = (lon2 - lon1) * degreesToRadians;

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * degreesToRadians) * Math.cos(lat2 * degreesToRadians) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return earthRadiusKm * c;
        },
        async clusterSensors(sensors) {
            /**
             * Clusters sensors based on their proximity.
             * Sensors are considered in the same area if they are less than 25km away.
             * Calculates the list of serialNumbers, maximum and minimum lat, and maximum and minimum lon for each cluster.
             */
            let clusters = [];

            for (let i = 0; i < sensors.length; i++) {
                const sensor = sensors[i];
                let clusterFound = false;

                for (let j = 0; j < clusters.length; j++) {
                    let cluster = clusters[j];
                    let distance = this.calculateDistance(sensor.lat, sensor.lon, cluster.lat, cluster.lon);

                    if (distance <= 25) {
                        cluster.serialNumbers.push(sensor.serialNumber);
                        cluster.minLat = Math.min(cluster.minLat, sensor.lat);
                        cluster.maxLat = Math.max(cluster.maxLat, sensor.lat);
                        cluster.minLon = Math.min(cluster.minLon, sensor.lon);
                        cluster.maxLon = Math.max(cluster.maxLon, sensor.lon);
                        cluster.lon = (cluster.maxLon + cluster.minLon)/2;
                        cluster.lat = (cluster.maxLat + cluster.minLat)/2;
                        clusterFound = true;
                        break;
                    }
                }

                if (!clusterFound) {
                    clusters.push({
                        serialNumbers: [sensor.serialNumber],
                        minLat: sensor.lat,
                        maxLat: sensor.lat,
                        lat: sensor.lat,
                        lon: sensor.lon,
                        minLon: sensor.lon,
                        maxLon: sensor.lon
                    });
                }
            }

            return clusters;
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors',
        }),
        ...Vuex.mapGetters('user', {
            currentUser: 'getUser'
        }),
        ...Vuex.mapGetters('filters', {
            filters: 'filters',
            areDataLoading: 'areDataLoading',
        }),
        ...Vuex.mapGetters('userReport', {
            userReports: 'userReports',
        }),
        ...Vuex.mapGetters('rollingRisk', {
            getMaxRiskBySensor: 'getMaxRiskBySensor',
            globalActualRisksLoading: 'globalActualRisksLoading',
        }),
        riskList() {
            return risk.getRiskList().reverse()
        },
        isAuthorizedForTest() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ADMIN') ||
                currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                currentUser.roles.includes('ROLE_LIFYAIR_TEST') ||
                currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
        },
        isAuthorized() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ADMIN') ||
                currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
        },
        getMinZoom() {
            return this.minZoom;
        },
        getMaxZoom() {
            return this.maxZoom;
        },
        clusterOptions() {
            return {
                spiderfyOnMaxZoom: true,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: true,
                removeOutsideVisibleBounds: true,
                spiderLegPolylineOptions: {weight: 1, color: '#7eec2d', opacity: 1},
                chunkedLoading: true,
                iconCreateFunction: (cluster) => {
                    const childCount = cluster.getChildCount();
                    const markers = cluster.getAllChildMarkers();
                    let risksByCluster = [];
                    markers.forEach((marker) => {
                        let stringColor = marker.options.icon.options.stringColor;
                        if (stringColor) {
                            risksByCluster.push(risk.riskValueByColor(stringColor));
                        }
                    })
                    const notDisconnected = risksByCluster.filter(risk => risk !== 'off')
                    let max;
                    if (notDisconnected.length === 0) {
                        max = 'off';
                    } else {
                        max = Math.max(...notDisconnected);
                    }

                    const classBySize = {1: 'small', 2: 'medium', 3: 'large', 4: 'extra-large'};
                    let html = `
                        <svg width="50" height="60" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 40C10.6333 35.4333 6.625 31.1917 3.975 27.275C1.325 23.3583 0 19.7333 0 16.4C0 11.4
                             1.60833 7.41667 4.825 4.45C8.04167 1.48333 11.7667 0 16 0C20.2333 0 23.9583 1.48333 27.175
                             4.45C30.3917 7.41667 32 11.4 32 16.4C32 19.7333 30.675 23.3583 28.025 27.275C25.375 31.1917
                             21.3667 35.4333 16 40Z" fill="${pollenService.getHexColorByValue(max.toString())}"/>
                        </svg>
                       <p class="marker-cluster-custom marker-${classBySize[childCount.toString().length]}">
                            ${childCount}
                       </p>`

                    return new L.DivIcon({
                        html: html,
                        className: `marker-cluster marker-cluster-custom`,
                        iconSize: new L.Point(40, 40)
                    });
                }
            };
        }
    },
    async created() {
        await this.setCurrentAccount();
        await this.updateRollingRisks();
        if (this.isAuthorized) {
            await this.updateGlobalConcentrations();
            await this.setNotificationSettings();
        }
        await this.setSensorsConnexionCount();
        await this.setSensors();
        if (this.sensors.length > 0) {
            await this.setSensorsPosition()
        }

        if (this.userReports.length > 0) {
            await this.setUserReportsPosition()
        }

        L.Map.include({
            _initControlPos: function () {
                let corners = this._controlCorners = {},
                    l = 'leaflet-',
                    container = this._controlContainer =
                        L.DomUtil.create('div', l + 'control-container', this._container);

                function createCorner(vSide, hSide) {
                    let className = l + vSide + ' ' + l + hSide;

                    corners[vSide + hSide] = L.DomUtil.create('div', className, container);
                }

                createCorner('top', 'left');
                createCorner('top', 'right');
                createCorner('top', 'center');

                createCorner('bottom', 'left');
                createCorner('bottom', 'right');
            }
        })
    },
    watch: {
        filters: {
            async handler(value) {
                await this.setDataLoading(true);
                let data = {...value};
                if (data.date) {
                    data.date = moment(data.date).format();
                }

                data.sensors = data.sensors.map(item => item.serialNumber);
                data.accounts = data.accounts.map(item => item.id);
                data.pollens = data.pollens.map(item => item.name);
                const params = {params: data};

                await this.updateRollingRisks(params);
                if (value.pollens.length === 0 && value.riskLevels.length === 0) {
                    await this.setSensorsConnexionCount(params);
                    if (this.isAuthorized) {
                        await this.setNotificationSettings(params);
                        await this.updateGlobalConcentrations(params);
                    }
                }
                if (value.showUserReports) {
                    let clusters = await this.clusterSensors(this.sensors.filter(item => item.status === "prod"))
                    let userReportsParams = {};

                    if (this.isAuthorized) {
                        const distance = this.calculateMaxDistance();
                        userReportsParams =
                            {
                                params: {
                                    latitude: this.center.lat,
                                    longitude: this.center.lng,
                                    distance: distance + 25,
                                    date: data.date,
                                }
                            }
                    } else {
                        userReportsParams =
                            {
                                params: {
                                    clusters:clusters,
                                    distance: 25,
                                    date: data.date,
                                }
                            }
                    }

                    const userReportsParamsHistory = JSON.parse(JSON.stringify(userReportsParams));
                    if (this.isAuthorized) {
                        userReportsParams.params.pollens = data.pollens;
                    }


                    await this.setUserReports(userReportsParams).then(() => {
                        this.setUserReportsPosition();
                    })

                    await this.setUserReportsHistory(userReportsParamsHistory);
                } else {
                    this.userReportsMarkers = [];
                }
                if (!value.fromMap) {
                    await this.updateSensors(params);
                }
                await this.setDataLoading(false);
            },
            deep: true
        },
    }
};
</script>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet-marker-icon {
    &.custom-map-pop-up {
        margin-top: -50px !important;

        &.chosen {
            margin-top: -70px !important;
        }
    }
}

.custom-map-pop-up {
    &.user-reports {
        z-index: 100 !important;
    }
}

.marker-cluster {
    &.marker-cluster-custom-user-reports {
        z-index: 350 !important;
    }
}

.marker-cluster-custom-user-reports {
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    color: white;
    background-color: rgba($dark-orange-lifyair, 0.6);

    div {
        width: 30px;
        height: 30px;
        font-weight: bold;
        background-color: rgba($dark-orange-lifyair, 0.8);
    }

}

.marker-cluster-custom {
    font-size: 25px;
    color: $white;
    margin-left: 18px;
    margin-top: -50px;
    font-family: 'Myriad Pro Semibold', sans-serif;

    &.chosen {
        color: $darkslategrey;
    }

    &.user-reports {
        margin-left: 0;
        margin-top: 0;
    }

    &.marker-medium {
        font-size: 22px;
        margin-left: 12px;
    }

    &.marker-large {
        font-size: 20px;
        margin-left: 9px;
    }

    &.marker-extra-large {
        font-size: 18px;
        margin-left: 5px;
    }

    &.bg-marker-cadetblue {
        background-color: rgba($cadetblue, 0.6);

        div {
            background-color: rgba($cadetblue, 0.8);
        }
    }

    &.bg-marker-gold {
        background-color: rgba($gold, 0.6);

        div {
            background-color: rgba($gold, 0.8);
        }
    }

    &.bg-marker-orange-sandybrown {
        background-color: rgba($orange-sandybrown, 0.6);

        div {
            background-color: rgba($orange-sandybrown, 0.8);
        }
    }

    &.bg-marker-indianred {
        background-color: rgba($indianred, 0.6);

        div {
            background-color: rgba($indianred, 0.8);
        }
    }
}

circle {
    stroke-width: 5px;
    stroke-opacity: 0.6;
}

.leaflet-popup {
    bottom: 20px !important;
}

.map-pop-up-custom .leaflet-popup-content-wrapper {
    background: white;
    color: $dark-grey;
    box-shadow: 0 0 13px -7px black;
    border-radius: unset;
    padding: 0;
}

.leaflet-popup-content {
    min-width: 30rem;
    height: auto;
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .leaflet-popup-content {
        min-width: 90vw;
        width: auto;
        height: auto;
    }
}

.dashboard-overlay-card {
    @extend .headerless-height;
    width: 30vw;
    background-color: $white;
    overflow-y: scroll;
    overflow-x: hidden;
}

.map-container {
    height: calc(100vh - 56px);
    width: 100%;

    .map-size {
        height: calc(100vh - 56px);
        width: 70vw;
    }
}

.logo-account-container {
    z-index: 1;
    margin: 0 15px 0 0;
    width: 9rem;
    background-color: rgba($white, 0.8);

    .logo-account {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .logo-account-container {
        width: 6rem;
    }
    .dashboard-overlay-card {
        width: 100%;
    }
    .map-container {
        width: 100%;

        .map-size {
            width: 100%;

            &.open-dashboard {
                display: none;
            }
        }
    }
}

.dashboard-control-legend {
    .dashboard-legend {
        background-color: $white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        margin-left: 15px;
        z-index: 1;

        .dashboard-legend-item {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
            padding: 15px 10px;
        }
    }

    .dashboard-legend-trigger {
        cursor: pointer;
        background-color: $dark-orange-lifyair;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        margin-left: 15px;
        color: white;
        padding: 15px;
        z-index: 2;
    }

    .dashboard-legend-icon {
        width: 20px;
        height: 20px;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(10px);
    }
}

.dashboard-control {
    .toggle-dashboard {
        cursor: pointer;
        width: 50px;
        height: 50px;
        background-color: $dimgrey;
        color: white;
        box-shadow: 0 0 13px -7px black;
        margin: 15px;
        padding: 5px;
        transform: scale(1);
        animation: pulse 2s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.8);
        }

        70% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.8);
        }
    }
}

.slide-fade-enter-active {
    transition: all .6s ease;
}

.slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}

.leaflet-top {
    width: 100%;
    z-index: 750;
    margin: 0;

    .leaflet-control {
        margin: 0;
        width: 100%;
    }
}

.leaflet-bottom {
    z-index: 999;
}

.leaflet-center {
    left: 50%;
    transform: translate(-50%, 0);
}

.loader-live-pollen {
    height: 80vh;
    text-align: center;

    svg {
        animation-duration: 2s;
        animation-name: pulse;
        animation-iteration-count: infinite;
    }
}

.action-filter-control-close {
    border-radius: 10px;
    background-color: $dimgrey;
}

//#pollen-checkboxes {
//    span {
//        margin-top: 3px;
//        font-size: 14px;
//        padding: 7px;
//        font-weight: normal;
//        cursor: pointer;
//    }
//
//    .custom-control {
//        margin: 0;
//    }
//
//    .custom-checkbox .custom-control-label {
//        &::after, &::before {
//            display: none;
//        }
//    }
//}
//
//.leaflet-container a.leaflet-popup-close-button {
//    top: 13px;
//    right: 10px;
//    color: $dimgrey;
//    font-size: 1.5rem;
//    font-weight: normal;
//}

.leaflet-control-zoom .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    border-width: 0;
}

</style>
