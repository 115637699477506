import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import './plugins/axios'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import "./filters"
import Amplify from "aws-amplify";
import VueApexCharts from 'vue-apexcharts'
import VueGtag from "vue-gtag";

let moment = require('moment-timezone');
const timezone = moment.tz.guess(true);
i18n.locale = store.getters.getAppLanguage
moment.locale(store.getters.getAppLanguage) // can pass in 'en', 'fr', or 'es'
moment.tz.setDefault(timezone);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
store.dispatch('login/checkHealth');

Amplify.configure({
    VUE_APP_BASE_URL: 'VUE_APP_API_URL',
    VUE_APP_ADMIN_URL: 'VUE_APP_ADMIN_URL',
    VUE_APP_API_URL: 'VUE_APP_API_URL',
    VUE_APP_I18N_LOCALE: 'VUE_APP_I18N_LOCALE',
    VUE_APP_I18N_FALLBACK_LOCALE: 'VUE_APP_I18N_FALLBACK_LOCALE',
    encodingType: 'ENCODING_TYPE',
    encryptionSecret: 'ENCRYPTION_SECRET',
    VUE_APP_AWS_ASSETS_BUCKET: 'VUE_APP_AWS_ASSETS_BUCKET',
})

const isEnable = localStorage.getItem('acceptedCookies') === 'accept';

Vue.use(VueGtag, {
    config: {id: "G-6RKCDLZRFM"},
    enabled: isEnable,
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
        return {
            page_title: to.name,
            page_path: to.path,
            page_location: to.location,
        }
    }
}, router);

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
